/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(GatsbyImage, {
    alt: "alt text",
    style: {
      maxWidth: "90%"
    },
    image: getImage(props.data.mdx.frontmatter.embeddedImagesLocal[0])
  }), "\n", React.createElement(_components.p, null, "An agreement grounded in Indigenous legal traditions, the Dish with One Spoon is one of several Wampum that commemorate the relationship between the Anishinaabek and Haudenosaunee Confederacy. Today it is held up by Indigenous Peoples in Toronto as peace agreement between the Anishinaabek and Haudensaunee. It is also widely cited (and admired) for the reciprocal responsibilities with all of creation that it foregrounds, a reading that draws on the land as a dish to be shared and cared for to ensure ongoing sustenance and life - a metaphor that is part of many Indigenous knowledge systems."), "\n", React.createElement(_components.p, null, "An agreement grounded in Indigenous legal traditions, the Dish with One Spoon is one of several Wampum that commemorate the relationship between the Anishinaabek and Haudenosaunee Confederacy. Today it is held up by Indigenous Peoples in Toronto as peace agreement between the Anishinaabek and Haudensaunee. It is also widely cited (and admired) for the reciprocal responsibilities with all of creation that it foregrounds, a reading that draws on the land as a dish to be shared and cared for to ensure ongoing sustenance and life - a metaphor that is part of many Indigenous knowledge systems."), "\n", React.createElement("img", {
    className: "toom dish-belt",
    src: "/tooms/tooms-dish-belt.png"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
